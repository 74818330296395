<template>
  <div class="learnMore">
    <div class="title">了解更多</div>
    <div class="main-box">
      <img class="left-img" src="@/assets/image/productCenter/learnMore-bg.png" alt="了解更多" srcset="">
      <div class="list">
        <div v-for="(item,index) in list.filter(it=>it.name!=routeName)" class="item" @click="$router.push({name:item.name})" :key="index">
          <img class="icon" :src="require(`@/assets/icons/productCenter/${item.icon}`)" alt="标志icon" srcset="">
          <div class="label">{{item.label}}</div>
          <svg-icon class="arrow" icon-class="arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// arrow-right
export default {
  data () {
    return {
      list: [
        {
          icon: 'learnMore-icon1.png',
          name: 'product-orgCert',
          label: '机构证书'
        },
        {
          icon: 'learnMore-icon2.png',
          name: 'product-personCert',
          label: '个人证书'
        },
        {
          icon: 'learnMore-icon3.png',
          name: 'product-sealBox',
          label: '智能印章一体机'
        },
        {
          icon: 'learnMore-icon4.png',
          name: 'product-eSeal',
          label: '电子签章系统'
        },
        {
          icon: 'learnMore-icon5.png',
          name: 'product-verifySign',
          label: '签名验签'
        },
        {
          icon: 'learnMore-icon6.png',
          name: 'product-timestamp',
          label: '可信时间戳'
        },
        {
          icon: 'learnMore-icon7.png',
          name: 'product-mobileShield',
          label: '手机盾'
        }
      ]
    }
  },
  created () {
    this.routeName = this.$route.name
  }
}
</script>

<style lang="scss" scoped>
.learnMore {
  padding: 160px 0;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 86px;
  }
  .main-box {
    display: flex;
    .left-img {
      width: 362px;
      height: 213px;
      margin-right: 210px;
      margin-top: 157px;
      flex-shrink: 0;
    }
    .list {
      width: 100%;
      & > div {
        height: 152px;
        border-bottom: 1px #e1e2e6 solid;
        align-items: center;
        display: flex;
        cursor: pointer;
        .icon {
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          margin-right: 32px;
        }
        .label {
          width: 100%;
          font-size: 18px;
          color: #303b50;
          line-height: 24px;
        }
        .arrow {
          color: #c6cadb;
          width: 32px;
          height: 12px;
          flex-shrink: 0;
        }
        &:last-child {
          border: none;
        }
        &:hover {
          .label,
          .arrow {
            color: $primaryColor;
          }
        }
      }
    }
  }
}
</style>